import './Header.css'
import React from 'react';

export default function Header() {

    return (
        <div className='header'>
            tracking.ljunggren.io
        </div>
    );
}